import React from 'react';
import useForm from '../utils/useForm';
import useMailer from '../utils/useMailer';
import SEO from '../components/SEO';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
import {
  ErrorStyles,
  ContactStyles,
  FormStyles,
  FormTitleStyles,
  InfoStyles,
  InputStyles,
  SuccessStyles,
  TextareaStyles,
} from '../styles/ContactStyles';

export default function ContactPage() {
  const { values, updateValue } = useForm({
    name: '',
    email: '',
    message: '',
    mapleSyrup: '',
  });
  const { error, loading, submitMessage, sendEmail } = useMailer(values);

  return (
    <>
      <SEO title={'Contact'} />
      <ContactStyles>
        <InfoStyles>
          <h2>Get in touch with us!</h2>
          <p>Proudly serving all of Humboldt county</p>
          <p>
            Call us at <a href="tel:+17073826550">(707) 382 - 6550</a>
          </p>
          <p>Visit us online!</p>
          <a
            href="https://www.instagram.com/humboldtcrittersitter/"
            target="_blank"
            rel="norefferer"
          >
            <FaInstagram className="insta" />
          </a>
          <a
            href="https://www.facebook.com/Humboldtcrittersitter"
            target="_blank"
            rel="norefferer"
          >
            <FaFacebookSquare className="fb" />
          </a>
        </InfoStyles>
        {submitMessage ? (
          <SuccessStyles success={submitMessage}>{submitMessage}</SuccessStyles>
        ) : (
          <FormStyles onSubmit={sendEmail} success={submitMessage}>
            <FormTitleStyles>Questions or Request Service</FormTitleStyles>
            <label htmlFor="name">Name</label>
            <InputStyles
              type="text"
              name="name"
              value={values.name}
              onChange={updateValue}
              disabled={loading}
            />
            <label htmlFor="email">Email</label>
            <InputStyles
              type="email"
              name="email"
              value={values.email}
              onChange={updateValue}
              disabled={loading}
            />
            <InputStyles
              type="mapleSyrup"
              name="mapleSyrup"
              value={values.mapleSyrup}
              onChange={updateValue}
              disabled={loading}
              className="mapleSyrup"
            />
            <label htmlFor="message">Message</label>
            <TextareaStyles
              type="textarea"
              name="message"
              value={values.message}
              onChange={updateValue}
              disabled={loading}
            />
            {error && <ErrorStyles>{error}</ErrorStyles>}
            <InputStyles
              type="submit"
              value={loading ? 'Sending...' : 'Send!'}
              disabled={loading}
            />
          </FormStyles>
        )}
      </ContactStyles>
    </>
  );
}
