import { useState } from 'react';

export default function useForm({ name, email, message, mapleSyrup }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  // Submit function
  async function sendEmail(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSubmitMessage(null);

    const body = {
      name,
      email,
      message,
      mapleSyrup,
    };

    const res = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/sendEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const text = JSON.parse(await res.text());

    // Check for error/success
    if (res.status >= 400 && res.status < 600) {
      setLoading(false);
      setError(text.message);
    } else {
      setLoading(false);
      setSubmitMessage('We will be in touch shortly!');
    }
  }

  return { error, loading, submitMessage, sendEmail };
}
