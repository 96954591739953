import styled from 'styled-components';

export const ContactStyles = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 5rem;

  @media (min-width: 800px) {
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  @media (min-width: 1200px) {
    margin: 5rem 15rem;
  }
  @media (min-width: 1400px) {
    margin: 5rem 25rem;
  }
`;

export const InfoStyles = styled.div`
  text-align: center;
  border-bottom: solid black 1px;
  padding-bottom: 3rem;
  margin-bottom: 3em;

  @media (min-width: 800px) {
    border: none;
    padding: 0;
    margin: 0;
  }
  .insta {
    fill: red;
    font-size: 3rem;
    margin-right: 2rem;
  }
  .fb {
    fill: blue;
    font-size: 3rem;
  }
`;

export const FormStyles = styled.form`
  display: flex;
  flex-flow: column nowrap;
  width: 90%;

  .mapleSyrup {
    display: none;
  }

  @media (min-width: 800px) {
    width: 40%;
  }
`;

export const FormTitleStyles = styled.h3`
  margin-bottom: 3rem;
  text-align: center;
`;

export const InputStyles = styled.input`
  border: 1px grey solid;
  border-radius: 0.5rem;
  margin: 1rem 0;
  padding: 0.8rem;
`;

export const TextareaStyles = styled.textarea`
  border: 1px grey solid;
  border-radius: 0.5rem;
  margin: 1rem 0;
  padding: 0.8rem;
  height: 10rem;
  white-space: pre-line;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  resize: vertical;
`;

// TODO Figure out transition animations without changing the flow
export const SuccessStyles = styled.div`
  text-align: center;
  width: 40%;
`;

export const ErrorStyles = styled.div`
  color: red;
  text-align: center;
`;
